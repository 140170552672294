(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.reset.controller:ResetCtrl
   *
   * @description
   *
   */
  angular
    .module('login.reset')
    .controller('ResetCtrl', ResetCtrl);

  function ResetCtrl($state, $stateParams, Restangular) {
    var vm = this;
    vm.ctrlName = 'ResetCtrl';
    vm.submit = submit;
    vm.form = {
      password: '',
      password2: '',
      token: $stateParams.token
    };
    vm.errorMessage = '';

    function submit() {

      if (vm.form.password !== vm.form.password2) {
        vm.errorMessage = 'Passwords do not match';
        return;
      }

      if (vm.form.password == '' || vm.form.password2 == '') {
        vm.errorMessage = 'Please enter your new password twice';
        return;
      }

      Restangular.all('reset-password').post(vm.form).then(function () {
        $state.go('login.login');
      }, function (error) {
        var err = error.data.errors[0];
        if (error.status == 404) {
          vm.errorMessage = 'No password reset request was found, please click CANCEL and restart the process';
        } else if (err.code == 'password-invalid') {
          vm.errorMessage = 'Please choose a password with ' + err.detail.min_len + ' to ' + err.detail.max_len + ' characters';
        } else {
          vm.errorMessage = err.message;
        }
      });
    }
  }
}());
